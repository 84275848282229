<template>
  <div class="Error404 blank-page" data-col="blank-page">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-header row"></div>
        <div class="content-body">
          <!-- Error page-->
          <div class="misc-wrapper">
            <router-link class="brand-logo" to="/">
              <img src="/app-assets/images/vakilgiri-logo.png" style="max-width: 150px" />
              <!-- <h2 class="brand-text text-primary ms-1">Vuexy</h2> -->
            </router-link>

            <p class="d-block d-xl-none d-lg-none d-md-none mt-3"></p>
            <div class="misc-inner p-2 p-sm-3">
              <div class="w-100 text-center">
                <div class="card mt-3" style="box-shadow: 0px 0px 5px 0px gray">
                  <div class="card-body text-center">
                    <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
                    <p class="mb-2">
                      Oops! 😖 The requested URL was not found on this server.
                    </p>
                    <center>
                      <img
                        class="d-none d-xl-block d-lg-block d-md-block"
                        style="width: 40%"
                        src="/assets/image/error.png"
                        alt="Error page"
                      />

                      <img
                        class="d-block d-xl-none d-lg-none d-md-none"
                        style="width: 100%"
                        src="/assets/image/error.png"
                        alt="Error page"
                      />
                    </center>

                    <div>
                      <button
                        class="btn btn-sm text-white mb-2"
                        style="background-color: #00364f"
                        @click="backBtnClick(role_id)"
                      >
                        Back to home
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- / Error page-->
        </div>
      </div>
    </div>
    <!-- END: Content-->
  </div>
</template>

<script>
export default {
  name: "Error404",
  data() {
    return {
      userData: {},
      role_id: 0,
    };
  },
  methods: {
    getData() {
      if (JSON.parse(localStorage.getItem("userData"))) {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        console.log(this.userData);
        this.role_id = this.userData.role_id;
      }
    },
    backBtnClick(role_id) {
      // 1-Super Admin
      // 2-Staff
      // 3-Professional
      // 4-Admin
      // 5-Verifier
      // 6-Master Distributer
      // 7-Distributer
      // 8-Retailer
      // 9-Client
      if (localStorage.getItem("accessToken") != null) {
        if (role_id == 1) {
          window.location.href = "/super-admin/home";
        } else if (role_id == 2) {
          window.location.href = "/staff/home";
        } else if (role_id == 3) {
          window.location.href = "/professional/home";
        } else if (role_id == 4) {
          window.location.href = "/admin/home";
        } else if (role_id == 5) {
          window.location.href = "/verifier/home";
        } else if (role_id == 6) {
          window.location.href = "/master-distributer/home";
        } else if (role_id == 7) {
          window.location.href = "/distributer/home";
        } else if (role_id == 8) {
          window.location.href = "/retailer/home";
        } else if (role_id == 9) {
          this.$router.push({ path: "/client/home" });
        } else {
          window.location.href = "/retailer/home";
        }
      } else {
        // window.location.replace(location.protocol + "//" + location.host + "/");

        window.location.href = "/home";
      }
    },
  },
  created() {
    this.getData();
    // if (localStorage.getItem("accessToken") == null) {
    //   this.$router.push({ name: "home" });
    // }
    // this.$router.push({ name: "login" });
  },
};
</script>

<style scoped>
.brand-logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  margin: 0;
  z-index: 1;
  justify-content: unset;
}
</style>
